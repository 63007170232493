<template>
  <div></div>
</template>

<script>
export default {
  name: "Latest"
};
</script>

<style lang="scss" scoped></style>
